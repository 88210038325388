import Head from '@app/components/molecules/Head';
import TemplateContainer from '@app/components/atoms/TemplateContainer';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';
import { useWindowSize } from '@app/hooks/useWindowSize';
import Button from '@app/components/atoms/Button';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CompassLogo from '@public/images/other-logos/compass-logo.png';
import SideLogo from '@public/images/other-logos/side-logo.png';
import SotherbysLogo from '@public/images/other-logos/sotherbys-logo.png';
import ColdwellLogo from '@public/images/other-logos/coldwell-logo.png';
import ThreeT from '@public/images/three-t.png';
import propertiesScreenShot1 from '@public/images/home/property-screenshot1.png';
import propertiesScreenShot2 from '@public/images/home/property-screenshot2.png';
import BrowserWindow from '@app/components/atoms/BrowserWindow';
import BuyersScreenGif from '@public/images/home/search_and_highlights.gif';

import * as Styled from './styles';

interface MyWindow extends Window {
  Intercom?: (event: string, options?: unknown | null) => void;
}

const Home = () => {
  const { width } = useWindowSize();
  const isTablet = width <= 1200;
  const isMobile = width <= 975;

  const [waitlistModal, setWaitlistModal] = useState(false);

  const w = window as MyWindow;

  const featureListRef = useRef<HTMLDivElement>(null);
  const buyersListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      },
    );

    if (featureListRef.current) {
      observer.observe(featureListRef.current);
    }

    if (buyersListRef.current) {
      observer.observe(buyersListRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Styled.Container>
      <Head path="/" />

      <main>
        <TemplateContainer>
          <Styled.Container>
            <Styled.HeroSection>
              <Styled.HeroContent>
                <Styled.HeroTitle>
                  Close more deals with off-market properties
                </Styled.HeroTitle>

                <Styled.HeroDescription>
                  Match buyers with exclusive inventory while turning homeowner
                  clients into future listings. Join top agents unlocking
                  opportunities others can&apos;t see.
                </Styled.HeroDescription>

                <Styled.HeroButtonContainer>
                  <Link href="/start/agent/about-you" passHref>
                    <Button variant="primary">
                      Get started free{' '}
                      <Styled.ArrowRightIcon icon={['fas', 'arrow-right']} />
                    </Button>
                  </Link>
                  <a
                    href="https://calendly.com/adamgothelf/trusty-demo"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button variant="secondary">Request demo</Button>
                  </a>
                </Styled.HeroButtonContainer>
              </Styled.HeroContent>
            </Styled.HeroSection>
            <Styled.TrustedSection>
              <Styled.TrustedTitle>
                Trusted by Agents at Premier Brokerages
              </Styled.TrustedTitle>

              <Styled.BrokerageRow>
                <Styled.BrokerageContainer>
                  <Styled.BrokerageLogos>
                    <Image
                      src={SotherbysLogo}
                      alt="Sotheby's International Realty"
                      height={20}
                      priority
                    />
                  </Styled.BrokerageLogos>
                  <Styled.BrokerageLogos>
                    <Image
                      src={CompassLogo}
                      alt="Compass"
                      height={20}
                      priority
                    />
                  </Styled.BrokerageLogos>
                  <Styled.BrokerageLogos>
                    <Image
                      src={ColdwellLogo}
                      alt="Coldwell Banker"
                      height={20}
                      priority
                    />
                  </Styled.BrokerageLogos>
                  <Styled.BrokerageLogos>
                    <Image src={SideLogo} alt="Side" height={20} priority />
                  </Styled.BrokerageLogos>
                </Styled.BrokerageContainer>
              </Styled.BrokerageRow>
            </Styled.TrustedSection>
            <Styled.OpportunitySection>
              <Styled.OpportunityGrid>
                <Styled.OpportunityThreeT>
                  <Image src={ThreeT} alt="Three Logo" />
                </Styled.OpportunityThreeT>
                <Styled.TitleCard>
                  <h2>Discover Off-Market Opportunities</h2>
                  <p>
                    Our smart matching system helps you uncover perfect
                    properties for your buyers from exclusive off-market
                    inventory.
                  </p>
                </Styled.TitleCard>
                <Styled.FeatureCard>
                  <Styled.IconWrapper>
                    <Styled.Icon icon={['fas', 'brain-circuit']} />
                  </Styled.IconWrapper>
                  <Styled.CardTitle>Intelligent Search</Styled.CardTitle>
                  <Styled.CardDescription>
                    Describe exactly what your buyers want - from high ceilings
                    to mature landscaping - and let our smart search find the
                    perfect matches.
                  </Styled.CardDescription>
                </Styled.FeatureCard>

                <Styled.FeatureCard>
                  <Styled.IconWrapper>
                    <Styled.Icon icon={['fas', 'users']} />
                  </Styled.IconWrapper>
                  <Styled.CardTitle>
                    Stay Connected with Past Clients
                  </Styled.CardTitle>
                  <Styled.CardDescription>
                    Keep relationships active and uncover new listing
                    opportunities when buyer agents express interest in your
                    past clients&apos; homes.
                  </Styled.CardDescription>
                </Styled.FeatureCard>

                <Styled.FeatureCard>
                  <Styled.IconWrapper>
                    <Styled.Icon icon={['fas', 'chart-network']} />
                  </Styled.IconWrapper>
                  <Styled.CardTitle>
                    Grow Your Hidden Inventory
                  </Styled.CardTitle>
                  <Styled.CardDescription>
                    Our network helps you discover more off-market properties,
                    giving your buyers access to opportunities they can&apos;t
                    find elsewhere.
                  </Styled.CardDescription>
                </Styled.FeatureCard>
              </Styled.OpportunityGrid>
            </Styled.OpportunitySection>
            <Styled.PropertiesSection>
              <Styled.PropertiesContainer>
                <Styled.PropertiesThreeT>
                  <Image src={ThreeT} alt="Three Logo" />
                </Styled.PropertiesThreeT>
                <Styled.PropertiesContent>
                  <Styled.PropertiesTitle>
                    Turn Past Buyers Into Tomorrow&apos;s Listings
                  </Styled.PropertiesTitle>

                  <Styled.FeatureList ref={featureListRef}>
                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'users']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          Nurture Client Relationships
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Keep in touch with past clients and be notified when
                          other agents&apos; buyers express interest in their
                          homes.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>

                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'chart-line']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          Property Insights
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Add property highlights and manage selling preferences
                          to better serve your homeowner clients.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>

                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'list-check']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          Future Listing Pipeline
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Track homeowner interest in selling and manage
                          property details, pricing, and notes all in one place.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>
                  </Styled.FeatureList>
                </Styled.PropertiesContent>

                <Styled.PropertiesImage>
                  <Styled.ListBrowserWindow>
                    <BrowserWindow height="400px" width="400px">
                      <Image
                        src={propertiesScreenShot1}
                        alt="Properties List"
                        width={400}
                        height={400}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        priority
                      />
                    </BrowserWindow>
                  </Styled.ListBrowserWindow>

                  <Styled.DetailBrowserWindow>
                    <BrowserWindow height="400px" width="400px">
                      <Image
                        src={propertiesScreenShot2}
                        alt="Property Detail"
                        width={400}
                        height={400}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        priority
                      />
                    </BrowserWindow>
                  </Styled.DetailBrowserWindow>
                </Styled.PropertiesImage>
              </Styled.PropertiesContainer>
            </Styled.PropertiesSection>
            <Styled.LogoDivider />
            <Styled.BuyersSection>
              <Styled.BuyersContainer>
                <Styled.BuyersContent>
                  <Styled.BuyersTitle>
                    Search The Way Your Buyers Think
                  </Styled.BuyersTitle>

                  <Styled.BuyersList ref={buyersListRef}>
                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'quote-left']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          Natural Language Search
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Enter buyer preferences in plain English, just like
                          they tell you. Our AI will find properties that match
                          their vision.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>
                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'microchip-ai']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          AI-Powered Scoring
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Our advanced AI ranks properties based on how well
                          they match your buyers&apos; specific needs and
                          preferences.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>

                    <Styled.Feature>
                      <Styled.FeatureIcon>
                        <FontAwesomeIcon icon={['fas', 'list-check']} />
                      </Styled.FeatureIcon>
                      <Styled.FeatureContent>
                        <Styled.FeatureTitle>
                          Property Highlights
                        </Styled.FeatureTitle>
                        <Styled.FeatureDescription>
                          Choose from curated property features that matter most
                          to your buyers, from modern kitchens to spacious
                          yards.
                        </Styled.FeatureDescription>
                      </Styled.FeatureContent>
                    </Styled.Feature>
                  </Styled.BuyersList>
                </Styled.BuyersContent>

                <Styled.BuyersImage>
                  <Styled.BuyersImageBrowser>
                    <BrowserWindow height="470px" width="550px">
                      <Image
                        src={BuyersScreenGif}
                        alt="Search Interface"
                        width={500}
                        height={397}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        priority
                      />
                    </BrowserWindow>
                  </Styled.BuyersImageBrowser>
                </Styled.BuyersImage>
              </Styled.BuyersContainer>
            </Styled.BuyersSection>
            <Styled.CTASection>
              <Styled.CTAContainer>
                <Styled.CTATitle>
                  Find homes your buyers can&apos;t see elsewhere
                </Styled.CTATitle>

                <Styled.CTADescription>
                  Agents are on the inside track for every home, especially
                  off-market homes. Tap your network to surface new inventory
                  for your buyer clients.
                </Styled.CTADescription>

                <Styled.CTAButton>
                  <Link href="/start/agent/about-you" passHref>
                    <Button variant="secondary">Get started free</Button>
                  </Link>
                </Styled.CTAButton>
              </Styled.CTAContainer>
            </Styled.CTASection>
          </Styled.Container>
        </TemplateContainer>
      </main>
    </Styled.Container>
  );
};

export default Home;
