import { NextSeo, NextSeoProps } from 'next-seo';
import { SoftwareAppJsonLd } from 'next-seo';
import React, { FunctionComponent } from 'react';
import { getVercelUrl } from '@app/utils/url';

interface Props extends NextSeoProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  imageAlt?: string;
  path?: string;
  url?: string;
}

const Head: FunctionComponent<Props> = ({
  title = '',
  description = 'Trusty is the first private, agent-to-agent networking platform that does the hard work of searching off-market for you.',
  imageUrl = 'https://static.trustyhomes.com/images/icon-192x192.png',
  imageAlt = 'Trusty Logo',
  path = '',
  url,
  openGraph,
  // For additional tags
  ...props
}) => {
  return (
    <>
      <NextSeo
        canonical={url || getVercelUrl(path)}
        title={title}
        defaultTitle="Trusty - Close more deals with off-market properties"
        titleTemplate="Trusty | %s - Close more deals with off-market properties"
        description={description}
        openGraph={{
          type: 'website',
          url: url || getVercelUrl(path),
          title: `${title} - Close more deals with off-market properties`,
          description: description,
          images: [
            {
              url: imageUrl,
              alt: imageAlt,
              width: 1200,
              height: 630,
            },
          ],
          site_name: 'Trusty',
          ...openGraph,
        }}
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'theme-color',
            content: '#ffffff',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/images/favicon-16x16.png',
            sizes: '16x16',
            type: 'image/png',
          },
          {
            rel: 'icon',
            href: '/images/favicon-32x32.png',
            sizes: '32x32',
            type: 'image/png',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/apple-touch-icon.png',
            sizes: '180x180',
          },
          {
            rel: 'manifest',
            href: '/site.webmanifest',
          },
        ]}
        {...props}
      />
      <SoftwareAppJsonLd
        name="Trusty"
        description={description}
        applicationCategory="RealEstateAgent"
        price="0"
        priceCurrency="USD"
        offers={{
          '@type': 'Offer',
          category: 'RealEstateProfessional',
        }}
        url={url || getVercelUrl(path)}
        operatingSystem="All"
        aggregateRating={{
          ratingValue: '5',
          ratingCount: '1',
          reviewCount: '1',
        }}
      />
    </>
  );
};

export default Head;
